import { useEffect, type FC } from 'react'
import Image, { ImageLoader, ImageLoaderProps, type ImageProps } from 'next/image'
import { appendRefetch, buildSrc } from '@/utils/helpers'
import Honeybadger from '@/modules/core/services/honeybadger.service'

export const TRANSFORM_URL = '/transform~rs_'
export const CROP_URL = '~sc_'

const shouldBuildSrc = (path: string) => path.indexOf(`${process.env.APP_MEDIA_API}/images/fetch`) < 0
const shouldTransform = (path: string) => !/~rs_/.test(path)
type appendTransformProps = Pick<OptimizedImageProps, 'src' | 'oneSize' | 'crop' | 'width'>
const appendTransform = ({ src, oneSize, crop, width }: appendTransformProps) => {
  if (oneSize && crop) {
    return `${src}${TRANSFORM_URL}${oneSize}.${oneSize}-fit${CROP_URL}${oneSize}`
  }
  if (oneSize) {
    return `${src}${TRANSFORM_URL}${oneSize}.h-fit`
  }
  if (crop) {
    return `${src}${TRANSFORM_URL}${width}.${width}-fit${CROP_URL}${width}.${width}`
  }
  return `${src}${TRANSFORM_URL}${width}.h-fit`
}

export const SIZES = [
  {
    w: 640,
    size: 600,
  },
  {
    w: 828,
    size: 600,
  },
  {
    w: 1200,
    size: 900,
  },
  {
    w: 1600,
    size: 1200,
  },
  {
    w: 3840,
    size: 1200,
  },
]

export type OptimizedImageProps = ImageProps & {
  oneSize?: number
  crop?: boolean
  forceTransform?: boolean
  objectFit?: string
  noCache?: boolean
}

export const ImageTKWW: FC<OptimizedImageProps> = ({ crop, oneSize, forceTransform, noCache, ...elementProps }) => {
  const getSize = (width: number) => {
    const foundSize = SIZES.find(({ w }) => width <= w)

    return foundSize?.size || SIZES[SIZES.length - 1].size
  }

  const loadImage: ImageLoader = ({ src, width }: ImageLoaderProps) => {
    let url = src
    if (shouldBuildSrc(src)) {
      url = buildSrc(src)
    }

    if (oneSize) {
      if (shouldTransform(src)) {
        url = appendTransform({ src: url, crop, oneSize })
      }
    }

    const size = getSize(width)

    if (shouldTransform(src)) {
      url = appendTransform({ src: url, crop, width: size })
    }

    // media api will cache items by default, so we need to append a refetch query param
    // to avoid this behavior when caching is not desired
    if (noCache) {
      url = appendRefetch(url)
    }
    return url
  }

  const width = oneSize ?? elementProps.width ?? 1200
  console.log('url', elementProps.src)
  console.log('width', width)
  const height = oneSize ?? elementProps.height ?? 1200
  let url = elementProps.src

  if (forceTransform || shouldBuildSrc(elementProps.src as string)) {
    url = buildSrc(elementProps.src as string)
    url = loadImage({ src: String(url), width: parseInt(`${width}`) })
  }

  // this useEffect will conditionally re-fetch the src of the image with a no-cache header,
  // thus invalidating the browser cache
  useEffect(() => {
    async function invalidateBrowserCache() {
      try {
        const result = fetch(url as string, { method: 'GET', cache: 'no-cache' })
      } catch (e) {
        Honeybadger.notify(`Unable to fetch proof image with no-cache header ${url}`, {
          name: 'failedToInvalidateProofImageCache',
          context: { url },
        })
      }
    }
    if (noCache) invalidateBrowserCache()
  }, [url, noCache])

  return noCache ? (
    // there is currently no easy way to disable the next file system cache, so we use a raw img
    // tag when we want to avoid caching
    // eslint-disable-next-line @next/next/no-img-element
    <img {...elementProps} src={url as string} alt={elementProps.alt ?? ''} width={width} height={height} />
  ) : (
    <Image {...elementProps} src={url} loader={loadImage} width={width} height={height} alt={elementProps.alt ?? ''} />
  )
}
